<template>
    <section class="setup-changes">
        <div class="row-fluid head title setup-changes__header">
            <h1>
                Account Health
            </h1>

            <section class="animated fadeIn delay-1000">
                <slotted-tag v-for="flag in flags"
                             :key="flag.label"
                             class="setup-changes__flag"
                             :class="{'setup-changes__flag--warning': flag.warning}"
                             :tooltip="flag.tooltip"
                             no-close>
                    <i v-if="flag.warning" class="symbol-warning"></i>
                    <i v-else-if="flag.icon" :class="flag.icon"></i>
                    {{flag.label}}
                </slotted-tag>
            </section>
        </div>

        <section v-if="!findNewMentions && !account.inactive">
            <dotted-card class="setup-changes__no-data">
                <p>
                    <i class="symbol-warning"></i>
                    This account is <strong>not collecting any new mentions</strong>
                </p>
            </dotted-card>
        </section>
        <section v-if="accountStatus === 'SUSPENDED'">
            <dotted-card class="setup-changes__no-data">
                <p>
                    <i class="symbol-warning"></i>
                    <strong>Client access</strong> to this account has been <strong>suspended</strong>.
                </p>
                <p v-if="findNewMentions">
                    This account is <em>still</em> collecting new mentions.
                </p>
            </dotted-card>
        </section>

        <section class="setup-changes__description">
            <p>View brands, usage, and account activity information for a chosen date range.</p>
            <published-input v-model="dateRange" :options="dateRangeOptions" />
        </section>

        <section class="category-buttons">
            <div :class="`category-buttons__button${show === sectionNames.BRANDS_LIST ? ' active' : ''}`" @click="setShow(sectionNames.BRANDS_LIST)">
                <h4>
                    Brands <deq-number :number="brands.length"/>
                </h4>
                <ul v-if="user.admin">
                    <li :class="fetchingSampling ? 'fetching-data' : null">
                        <i  v-if="countBrandsWithSamplingChanges > 0" class="symbol-warning"></i>
                        <spinner-component v-if="fetchingSampling" :size="16" />
                        <div>
                            <template v-if="samplingQuery.data.length > 0">
                                <deq-number :number="countBrandsWithSamplingChanges"/> {{ formatPlural(countBrandsWithSamplingChanges, 'brand') }}
                                with sampling changes

                            </template>
                            <template v-else>
                                No Crowd sampling changes
                            </template>
                        </div>
                    </li>
                    <li v-if="outstandingCrowd !== null && user.admin">
                        <span v-if="outstandingCrowd === 0">
                            All live sampling is complete
                        </span>
                        <span v-else>
                            <deq-number :number="outstandingCrowd"/> outstanding
                            {{formatPlural(outstandingCrowd, 'job')}} for live sampling
                        </span>
                    </li>
                    <li :class="fetchingRetrosends ? 'fetching-data' : null">
                        <i v-if="countBrandsWithRetrosends > 0" class="symbol-warning"></i>
                        <spinner-component v-if="fetchingRetrosends" :size="16" />
                        <div>
                            <template v-if="retrosendsQuery.data.length > 0">
                                <deq-number :number="retrosendsQuery.data.length"/> {{formatPlural(retrosendsQuery.data.length, "retrosend")}}
                            </template>
                            <template v-else>
                                No retrosends
                            </template>
                        </div>
                    </li>
                </ul>
            </div>

            <div :class="`category-buttons__button${show === sectionNames.USER_ACTIVITY ? ' active' : ''}`" @click="setShow(sectionNames.USER_ACTIVITY)">
                <h4>
                    Account usage
                    <i v-if="dashboardQuery.errorMessage" class="symbol-warning"></i>
                </h4>
                <ul>
                    <li :class="fetchingDashboardEdits ? 'fetching-data' : null">
                        <i v-if="dashboardQuery.wasLimited" class="symbol-warning"></i>
                        <spinner-component v-if="fetchingDashboardEdits" :size="16" />
                        <template v-if="dashboardsEdited.newerDashboards.length === 0">
                            No dashboards edited
                        </template>
                        <template v-else>
                            <deq-number :number="dashboardsEdited.newerDashboards.length" />
                            {{ formatPlural(dashboardsEdited.newerDashboards.length, 'dashboard') }} edited
                        </template>
                    </li>
                    <li :class="fetchingUsers ? 'fetching-data' : null" >
                        <spinner-component v-if="fetchingUsers" :size="16" />
                        <template v-if="clients.length === 0 && brandsEyeUsers.length === 0">
                            Account not accessed
                        </template>
                        <template v-else>
                            Account accessed by
                            <template v-if="clients.length > 0">
                                <deq-number :number="clients.length" /> {{formatPlural(clients.length, user.admin ? 'client' : 'user')}}
                                <template v-if="brandsEyeUsers.length > 0"> and </template>
                            </template>
                            <template v-if="user.admin && brandsEyeUsers.length > 0">
                                <deq-number :number="brandsEyeUsers.length" /> {{ formatPlural(brandsEyeUsers.length, 'staff member') }}
                            </template>
                            <template v-else-if="brandsEyeUsers.length > 0">
                                DataEQ
                            </template>
                        </template>
                    </li>
                    <li>
                        <template v-if="hasReporting && user.admin">This account includes reporting by DataEQ</template>
                        <template v-else-if="user.admin">DataEQ does not create reports for this account</template>
                        <template v-else-if="hasReporting">Your account includes reporting by DataEQ</template>
                        <template v-else>DataEQ does not create reports for you</template>
                        <i v-if="hasReporting" class="reporting-icon icon-chart-2"></i>
                    </li>
                </ul>
            </div>

            <div :class="`category-buttons__button${show === sectionNames.PROFILES ? ' active' : ''}`" @click="setShow(sectionNames.PROFILES)" v-if="user.admin">
                <h4>
                    Profiles <spinner-component v-if="profiles === null" :size="16"/> <deq-number v-else :number="getNonDeletedProfiles.length"/>
                </h4>
                <ul v-if="profiles !== null">
                    <li v-if="expiredProfiles.length">
                        <i class="symbol-warning"></i>
                        <deq-number :number="expiredProfiles.length"/> {{formatPlural(expiredProfiles.length, "Profile has", "Profiles have")}} expired
                    </li>
                    <li v-if="profilesExpiringSoon.length">
                        <i class="symbol-warning"></i>
                        <deq-number :number="profilesExpiringSoon.length"/> {{formatPlural(profilesExpiringSoon.length, "Profile")}} will expire soon
                    </li>
                    <li v-if="unauthorisedLinkedinProfiles.length">
                        <i class="symbol-warning"></i>
                        <deq-number :number="unauthorisedLinkedinProfiles.length"/> {{formatPlural(unauthorisedLinkedinProfiles.length, "Unauthorised LinkedIn profile")}}
                    </li>
                    <li v-if="noDmAuthorisedTwitterProfiles.length">
                        <i class="symbol-warning"></i>
                        <deq-number :number="noDmAuthorisedTwitterProfiles.length"/> {{formatPlural(noDmAuthorisedTwitterProfiles.length, "Authorised Twitter profile")}} not collecting DMs
                    </li>
                </ul>
            </div>

            <div :class="`category-buttons__button${show === sectionNames.HEALTH ? ' active' : ''}`"
                 @click="setShow(sectionNames.HEALTH)">
                <h4>Account Setup Warnings</h4>
                <ul>
                    <template v-if="healthWarnings && !isCheckingHealth">
                        <li v-if="uniqueWarningCount === 0">No warnings</li>
                        <li v-else>
                            <deq-number :number="uniqueWarningCount"/> {{formatPlural(uniqueWarningCount, 'warning')}}
                            <i class="symbol-warning"></i>
                        </li>
                    </template>
                    <li v-else-if="isCheckingHealth">
                        <spinner-component :size="16"/> Checking warnings ...
                    </li>
                    <li v-if="account.clientService && user.admin">
                        The account manager is <deq-user :user="account.clientService"/>
                    </li>
                </ul>
            </div>

            <div :class="`category-buttons__button${show === sectionNames.ACTIVITY_LOG ? ' active' : ''}`" @click="setShow(sectionNames.ACTIVITY_LOG)">
                <h4>Full activity log</h4>
            </div>
        </section>

        <transition name="rise">
            <div v-show="show === sectionNames.USER_ACTIVITY">
                <h4 v-if="dashboardQuery.wasLimited">
                    <i class="symbol-warning"></i>
                    Only showing the <deq-number :number="dashboardQuery.hardLimit"/> most recent logs
                </h4>
                <template v-if="dashboardQuery.errorMessage">
                    <i class="symbol-warning"></i> {{ dashboardQuery.errorMessage }}
                </template>
                <user-activity
                    :className="fetchingAccountUsageData ? 'fetching-data' : null"
                    :clients="clients"
                    :brandsEyeUsers="brandsEyeUsers"
                    :dashboards="dashboardsEdited"
                />
            </div>
        </transition>

        <transition name="rise">
            <section v-if="show === sectionNames.ACTIVITY_LOG">
                <activity-view title="" :dateRangeOverride="dateRange" />
            </section>
        </transition>

        <transition name="rise">
            <section v-show="show === sectionNames.PROFILES" :class="fetchingProfileLogs ? 'fetching-data' : null">
                <profile-changes ref="profileChanges" :dateRange="{start: from.toISOString(), end: toExclusive.toISOString()}" :profile-logs="profilesQuery.data"/>
            </section>
        </transition>

        <transition name="rise">
            <section v-if="show === sectionNames.HEALTH">
                <errors-and-warnings/>
            </section>
        </transition>

        <transition name="rise">
            <section v-show="show === sectionNames.BRANDS_LIST" :class="fetchingBrandsData ? 'fetching-data' : null">
                <div class="brand-toolbar">
                    <input type="text" v-model="brandNameFilterString" placeholder="Filter by brand name">
                    <template v-if="user.admin">
                        <div v-if="countBrandsWithSamplingChanges > 0 || countBrandsWithRetrosends > 0" class="brand-filters">
                            Only show brands with
                            <label v-if="countBrandsWithSamplingChanges > 0">
                                <input type="checkbox" v-model="onlyShowSampledBrands">
                                Sampling changes <deq-number :number="countBrandsWithSamplingChanges" />
                            </label>
                            <label v-if="countBrandsWithRetrosends > 0">
                                <input type="checkbox" v-model="onlyShowBrandsWithRetrosends">
                                Retrosends <deq-number :number="countBrandsWithRetrosends" />
                            </label>
                            <span v-if="countBrandsWithSamplingChanges === 0" class="muted">
                                No brands with Sampling changes
                            </span>
                            <span v-if="countBrandsWithRetrosends === 0"  class="muted">
                                No brands with Retrosends
                            </span>
                        </div>
                        <div v-else class="brand-filters muted">
                            There are no brands with Sampling changes or Retrosends
                        </div>
                    </template>
                </div>
                <section class="setup-changes__brand-list">
                    <template v-if="visibleBrands.length">
                        <brand-changes v-for="brand in visibleBrands"
                                    :key="brand.id"
                                    :brand="brand"
                                    :highlight="brandNameFilterString"
                                    :sampling="getBrandLogs(brand.id)"
                                    :retrosends="getBrandRetrosends(brand.id)"
                                    :volumes="brandVolumes[brand.id]"
                                    :dateRange="{start: from.toISOString(), end: toExclusive.toISOString()}"
                                    />
                    </template>
                    <span class="muted" v-else-if="user.admin && brands.length && onlyShowBrandsWithRetrosends && onlyShowSampledBrands">
                        No brands were found with both Sampling changes and Retrosends
                    </span>
                    <span class="muted" v-else-if="brands.length">
                        No brands were found for the given filters
                    </span>
                </section>
                <template v-if="user.admin">
                    <div v-if="olderRetrosends.length > 0">
                        <h4>Older retrosends</h4>
                        <div class="mini-log">
                            <table class="mini-log__table">
                                <retrosend-log
                                    v-for="retrosend in olderRetrosends"
                                    :log="retrosend"
                                    :key="retrosend.userId + ':' + retrosend.date"
                                />
                            </table>
                        </div>
                    </div>
                    <div v-if="olderSamplingChanges.length > 0">
                        <h4>Older sampling changes</h4>
                        <div class="mini-log">
                            <table class="mini-log__table">
                                <brand-sampling-log
                                    v-for="log in olderSamplingChanges"
                                    :key="log.date + ':' + log.metadata.brandId"
                                    :log="log"
                                />
                            </table>
                        </div>
                    </div>
                </template>
            </section>
        </transition>

    </section>
</template>

<script>
import moment from "moment";
import Vue from 'vue';
import axios, {CancelToken} from 'axios';
import {isFunction} from 'underscore';
import {beef, mash} from "@/store/Services";
import DeqNumber from "@/components/formatters/DeqNumber";
import {getUserType, isBrandsEyeUser} from '@/components/formatters/helpers';
import SpinnerComponent from "@/components/SpinnerComponent";
import PublishedInput from '@/components/inputs/PublishedInput';
import DottedCard from "@/components/cards/DottedCard";
import {mapActions, mapGetters, mapState} from "vuex";
import ErrorsAndWarnings from "@/setup/changes/ErrorsAndWarnings";
import {formatNumber, formatPlural} from "@/app/utils/Format";
import SlottedTag from "@/components/tags/SlottedTag";
import {setTitle} from "@/app/Beef";
import {parseDate, toDateRange} from "@/app/utils/Dates";
import {hasExpiredOrExpiresSoon, isProfileExpired} from "@/app/popup/token-expire/TokenUtilities";
import ProfileChanges from "@/setup/changes/ProfileChanges";
import DeqUser from "@/components/formatters/DeqUser";
import {grouseGet} from "@/data/Grouse";

const sectionNames = {
        ACTIVITY_LOG:'ACTIVITY_LOG',
        BRANDS_LIST:'BRANDS_LIST',
        USER_ACTIVITY:'USER_ACTIVITY',
        PROFILES:'PROFILES',
        HEALTH: 'HEALTH'
    };

    export default {
        name: "SetupChanges",
        components: {
            DeqUser,
            DeqNumber,
            SpinnerComponent,
            ProfileChanges,
            SlottedTag,
            ErrorsAndWarnings,
            DottedCard,
            BrandChanges: () => import('./BrandChanges'),
            UserActivity: () => import('./UserActivity'),
            ActivityView: () => import('@/components/activity-view/ActivityView'),
            PublishedInput,
            RetrosendLog: () => import('./RetrosendLog'),
            BrandSamplingLog: () => import('./BrandSamplingLog')
        },
        data() {
            return {
                hasReporting: null,
                findNewMentions: null,
                accountStatus: null,

                dateRange: "MONTH",
                dateRangeOptions: {
                    labelPrefix: 'Changed ',
                    promptText: {
                        overall: 'Include changes from the last',
                        multiple: 'changes'
                    }
                },

                sectionNames,
                show: sectionNames.BRANDS_LIST,

                brands: [],
                brandNameFilterString: '',
                onlyShowSampledBrands: false,
                onlyShowBrandsWithRetrosends: false,

                users: [],
                fetchingUsers: false,

                brandVolumes: {},
                fetchingBrandVolumes: false,
                outstandingCrowd: null,             // This is outstanding volumes for live sampling.

                dashboardQuery: {
                    hardLimit: 5000,
                    wasLimited: false,
                    cancelFunctions: [],
                    errorMessage: null,
                    data: []
                },

                samplingQuery: {
                    hardLimit: 1000,
                    wasLimited: false,
                    cancelFunctions: [],
                    errorMessage: null,
                    data: []
                },

                retrosendsQuery: {
                    hardLimit: 1000,
                    wasLimited: false,
                    cancelFunctions: [],
                    errorMessage: null,
                    data: []
                },

                profilesQuery: {
                    hardLimit: 1000,
                    wasLimited: false,
                    cancelFunctions: [],
                    errorMessage: null,
                    data: []
                }
            }
        },

        computed: {
            ...mapState(['account', 'user', 'rootBrands']),
            ...mapGetters(['idToBrand']),
            ...mapGetters('dashboards', ['idToDashboard']),
            ...mapState('health', {'healthWarnings': 'warnings', 'isCheckingHealth': 'isChecking'}),
            ...mapGetters('health', ['uniqueWarningCount', 'volumeWarningCount']),
            ...mapState('profiles', ['profiles']),
            ...mapGetters('profiles', ['getLinkedinProfiles', 'getTwitterProfiles', 'getNonDeletedProfiles']),

            flags() {
                const flags = [];

                if (this.account) {
                    const isArchived = this.account.status === "ARCHIVED";
                    const isInactive = !!this.account.inactive;
                    if (isInactive) flags.push({label: "INACTIVE", icon: "symbol-warning", tooltip: "This account is inactive"});
                    if (!this.account.findNewMentions && !isArchived && !isInactive) {
                        flags.push({
                            label: "Not collecting mentions",
                            tooltip: "This account is not collecting any new mentions. Please contact support.",
                            warning: true
                        });
                    }
                    if (this.account.findNewMentions && isArchived) flags.push({label: "Still collecting"});
                    if (this.account.status && this.account.status !== "NORMAL") flags.push({label: this.account.status});
                    if (!isInactive) {
                        if (this.account.hasEngage) flags.push({label: "Uses Engage", tooltip: "This account is setup to send data to Engage"});
                        if (this.account.hasReporting) flags.push({label: "Receives reporting", icon: "icon-chart-2", tooltip: "This account includes reporting from DataEQ"});
                        if (this.uniqueWarningCount) flags.push({label: `Has ${formatNumber(this.uniqueWarningCount)} ${formatPlural(this.uniqueWarningCount, 'warning')}`, icon: "symbol-warning"});
                        if (this.volumeWarningCount && this.user.admin) flags.push({label: `Has ${formatNumber(this.volumeWarningCount)} volume ${formatPlural(this.volumeWarningCount, 'warning')}`, icon: "symbol-warning"});
                    }
                }

                return flags;
            },

            range:      function() { return toDateRange(this.dateRange) },
            from:       function() { return this.dateRange === '24HOURS' ? moment().subtract(24, 'hours') : moment(this.range.start); },
            toExclusive:function() {
                return (
                      this.range.end === 'today'
                    ? moment().add(1, 'minute')
                    : moment(this.range.end).format('HHmm') !== '0000' // infer that the user specified a time
                    ? moment(this.range.end).add(1, 'minute')
                    : moment(this.range.end).add(1, 'day')
                )
            },
            visibleBrands() {
                let brands = !this.brandNameFilterString ? this.brands : this.brands.filter(brand => (brand.shortName || brand.name).toLowerCase().indexOf(this.brandNameFilterString.toLowerCase()) !== -1 );

                if(!this.user.admin) return brands

                const retrosendBrands = new Set();
                const sampledBrands = new Set();

                if(this.onlyShowSampledBrands) {
                    this.samplingChangesWithinRange.forEach(s => sampledBrands.add(s.metadata.brandId));
                    brands = brands.filter(b => sampledBrands.has(b.id))
                }
                if(this.onlyShowBrandsWithRetrosends) {
                    this.retrosendsQuery.data.forEach(s => {
                        // some older retrosend logs are not explicitly associated with a brand
                        if (s.metadata && s.metadata.brand && s.metadata.brand.id){
                            retrosendBrands.add(s.metadata.brand.id)
                        }
                    });
                    brands = brands.filter(b => retrosendBrands.has(b.id))
                }

                if (this.onlyShowSampledBrands && this.onlyShowBrandsWithRetrosends){
                    const intersection = new Set([...retrosendBrands].filter(id => sampledBrands.has(id)))
                    brands = brands.filter(b => intersection.has(b.id))
                }

                return brands
            },

            noDmAuthorisedTwitterProfiles() {
                let profiles = [];
                this.getTwitterProfiles.forEach(profile => {
                    if (!profile.deleted) {
                        if (profile.authorized && !profile.directMessagesEnabled) profiles.push(profile);
                    }
                });
                return profiles;
            },
            unauthorisedLinkedinProfiles() {
                let profiles = [];
                this.getLinkedinProfiles.forEach(profile => {
                   if (!profile.deleted) {
                       if (!profile.authorized) profiles.push(profile);
                   }
                });
                return profiles;
            },
            expiredProfiles() {
                let profiles = [];
                this.profiles.forEach(profile => {
                    if (!profile.deleted) {
                        if (isProfileExpired(profile)) profiles.push(profile);
                    }
                });
                return profiles;
            },
            profilesExpiringSoon() {
                let profiles = [];
                for (const profile of this.profiles) {
                    if (!profile.deleted) {
                        if (isProfileExpired(profile)) continue; // profile is already expired, not expiring soon
                        if (hasExpiredOrExpiresSoon(profile)) profiles.push(profile);
                    }
                }
                return profiles;
            },

            countBrandsWithSamplingChanges() {
                const withSamplingChanges = new Set();
                this.samplingChangesWithinRange.forEach(s => {
                    // some older sampling logs are not explicitly associated with a brand
                    if(s.metadata.brandId) {
                        withSamplingChanges.add(s.metadata.brandId)
                    }
                });
                return withSamplingChanges.size;
            },
            countBrandsWithRetrosends() {
                const withRetrosends = new Set();
                this.retrosendsQuery.data.forEach(s => {
                     // some older retrosend logs are not explicitly associated with a brand
                    if(s.metadata && s.metadata.brand && s.metadata.brand.id){
                        withRetrosends.add(s.metadata.brand.id)
                    }
                });
                return withRetrosends.size;
            },
            samplingChangesWithinRange() {
                return this.samplingQuery.data.filter(s => moment(s.date).isBetween(this.from, this.toExclusive));
            },
            olderSamplingChanges() {
                return this.samplingChangesWithinRange.filter(s => !(s.metadata.brandId))
            },
            olderRetrosends() {
                return this.retrosendsQuery.data.filter(s => !(s.metadata && s.metadata.brand && s.metadata.brand.id) )
            },
            brandsEyeUsers() {
                return this.users.filter(u => getUserType(u) !== 'NORMAL')
            },
            clients() {
                return this.users.filter(u => getUserType(u) === 'NORMAL')
            },
            dashboardsEdited() {
                const edited = {}
                const oldDashboardEdits = []

                this.dashboardQuery.data.forEach(edit => {

                    const dashboardId = edit.metadata && edit.metadata.id;
                    const dash = edited[dashboardId];
                    const editedByBrandsEye = isBrandsEyeUser(edit.user);

                    const parseDashName = (edit) => {
                        try {
                            return edit.description.split('Report [')[1].split(`:${dashboardId}]`)[0];
                        } catch (error) {
                            console.warn("Couldn't determine dashboard name for id:", dashboardId, error);
                            return '–';
                        }
                    }

                    if (typeof dashboardId === 'undefined'){
                        oldDashboardEdits.push(edit)
                    } else if(dash) {
                        if (dash.name === ('' + dash.id) && !dash._loading) {
                            this.$set(dash, 'deleted', true);
                            dash.name = parseDashName(edit);
                        }
                        if (editedByBrandsEye) {
                            dash.brandsEyeUserEdited = true
                        } else {
                            dash.clientEdited = true
                        }
                        dash.edits.push(edit)
                    } else {
                        const storeDash = this.idToDashboard.get(dashboardId);
                        let dashboardName = storeDash && storeDash.name;
                        if (!dashboardName) dashboardName = parseDashName(edit);

                        edited[dashboardId] = {
                            id: dashboardId,
                            link: `/${this.account.code}/dashboards/${dashboardId}`,
                            edits: [edit],
                            name: dashboardName,
                            clientEdited: !editedByBrandsEye,
                            brandsEyeUserEdited: editedByBrandsEye,
                        }
                    }
                })

                const dashboardsAsArray = Object.keys(edited).map(k => edited[k])

                return {
                    newerDashboards: dashboardsAsArray,
                    byClients: dashboardsAsArray.filter(d => d.clientEdited),
                    byBrandsEyeUsers: dashboardsAsArray.filter(d => d.brandsEyeUserEdited),
                    olderEdits: oldDashboardEdits
                }
            },
            totalRetrosentMentions() {
                if (!this.retrosendsQuery.data || !this.retrosendsQuery.data.length) return 0;

                let total = 0;
                for (const retrosend of this.retrosendsQuery.data) {
                    total += retrosend.metadata.mentionsSent;
                }

                return total;
            },
            fetchingDashboardEdits() {
                return this.dashboardQuery.cancelFunctions.filter(f => f !== null).length > 0
            },
            fetchingRetrosends() {
                return this.retrosendsQuery.cancelFunctions.filter(f => f !== null).length > 0
            },
            fetchingSampling() {
                return this.samplingQuery.cancelFunctions.filter(f => f !== null).length > 0
            },
            fetchingProfileLogs() {
                return this.profilesQuery.cancelFunctions.filter(f => f !== null).length > 0
            },

            fetchingAccountUsageData() {
                return this.fetchingDashboardEdits || this.fetchingUsers
            },
            fetchingBrandsData() {
                return this.fetchingRetrosends || this.fetchingSampling || this.fetchingBrandVolumes
            }
        },

        watch: {
            dateRange() {
                this.fetchData();
            }
        },

        async created() {
            setTitle("Account Health");

            this.hasReporting = this.account.hasReporting;
            this.findNewMentions = this.account.findNewMentions;
            this.accountStatus = this.account.status;

            // This uses dashboard data later. Here we make sure that store is loading that data.
            await Promise.all([
                this.refreshDashboards(),
                this.checkAllHealth()
            ])
        },

        async mounted() {
            await this.refreshBrands();
            this.brands = Array.from(this.rootBrands);
            this.brands.sort((lhs, rhs) => {
                if (lhs.category === rhs.category) return 0;
                if (lhs.category === 'OWN') return -1;
                if (rhs.category === 'OWN') return 1;
                if (lhs.category === 'COMPETITOR') return -1;
                if (rhs.category === 'COMPETITOR') return 1;
                return 0;
            });

            this.fetchData().catch(e => console.warn(e));
        },

        methods: {
            formatPlural,

            ...mapActions(['refreshBrands']),
            ...mapActions('dashboards', ['refreshDashboards']),
            ...mapActions('health', ['checkAllHealth']),
            ...mapActions('profiles', ['refreshProfiles']),

            async fetchData() {
                this.onlyShowBrandsWithRetrosends = false;
                this.onlyShowSampledBrands = false;

                // todo check that dates make sense.
                const toFetch = [];
                toFetch.push(this.fetchLogs( 'DASHBOARD', 'dashboardQuery'));
                toFetch.push(this.fetchUsers());
                toFetch.push(
                    this.fetchLogs( 'SAMPLING', 'samplingQuery')
                        .then(() => {
                            // sampling logs  must be fetched before volume usages
                            return this.fetchAccountVolumeUsage();
                        })
                );

                if (this.user.admin) {
                    toFetch.push(this.fetchLogs( 'RETROSEND', 'retrosendsQuery'));
                    toFetch.push(this.fetchLogs(null, 'profilesQuery'));
                    toFetch.push(this.fetchOutstanding());
                }

                await Promise.all(toFetch);
            },

            setShow(sectionName) {
                if(sectionNames[sectionName]){
                    this.show = sectionName
                } else {
                    throw new Error('No section named ' + sectionName)
                }
            },

            async fetchOutstanding() {
                this.outstandingCrowd = null;
                try {
                    const response = await beef.get(`/api/accounts/${this.account.code}/crowd/outstanding`, {
                        params: {
                            start: this.from.format("YYYY-MM-DD"),
                            end: this.toExclusive.clone().add(1, 'day').format("YYYY-MM-DD")
                        }
                    });

                    this.outstandingCrowd = response.data.count;
                } catch(e) {
                    console.error("Unable to read outstanding crowd data", e);
                    this.outstandingCrowd = null;
                }
            },

            async fetchLogs(type, queryStateKey, options) {
                const offset = (options && options.offset) || 0;
                const limit = (options && options.limit) || 1000;
                const append = (options && options.append) || false;

                // if we are fetching profile logs and we've already fetched profile logs before,
                // speed up the process by only fetch profile logs from today since we already have historic logs
                if (queryStateKey === 'profilesQuery' &&  this[queryStateKey].data?.length && !append) {
                    await this.fetchLatestProfileLogs();
                    return;
                }

                if (!append){
                    this[queryStateKey].wasLimited = false;
                    this[queryStateKey].data = [];
                    while(this[queryStateKey].cancelFunctions.length > 0){
                        const cancelFunction = this[queryStateKey].cancelFunctions.pop();
                        if(isFunction(cancelFunction)) cancelFunction(`Cancelling stale requests for ${queryStateKey}`);
                    }
                }

                let query;
                if (queryStateKey === 'profilesQuery') {
                    const profileLogStrings = ["profile has been authorised", "using existing token", "creating new token",
                        "profile has been unauthorised", "unauthorized from network", "authorisation has been refreshed", "has been added to the account.", "deleted."];

                    // We need to know the entire auth history of profiles in order to say whether or not the profile was authorised during a particular time period.
                    // get our "from" value from the creation date of the oldest non-deleted profile
                    await this.refreshProfiles();
                    let from = this.profiles.filter(profile => !profile.deleted).sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))[0].dateCreated;
                    query = [
                        `/rest/accounts/${this.account.code}/activity?from=${parseDate(from).toISOString()}`,
                        `to=${this.toExclusive.toISOString()}`,
                        `searchStrings=${profileLogStrings.join(",")}`,
                        `offset=${offset}`,
                        `limit=${limit}`
                    ].join('&');
                } else {
                    // for sampling logs, we always query for the latest ones for calculating the brand volume limits. Sampling logs outside of the chosen date range will not be shown in the sampling changes list
                    let to = queryStateKey === "samplingQuery" ? moment().toISOString() : this.toExclusive.toISOString();

                    query = [
                        `/rest/accounts/${this.account.code}/activity?type=${type}`,
                        `from=${this.from.toISOString()}`,
                        `to=${to}`,
                        `offset=${offset}`,
                        `limit=${limit}`
                    ].join('&');
                }

                this[queryStateKey].errorMessage = null;

                let response = null
                let cancelFunctionIndex = null
                try {
                    response = await mash.get( query, { cancelToken: new CancelToken(c => {
                        cancelFunctionIndex = this[queryStateKey].cancelFunctions.push(c) - 1 } )
                    });

                    if (append) {
                        this[queryStateKey].data = [...this[queryStateKey].data,...response.data.data];
                    } else {
                        this[queryStateKey].data = response.data.data;
                    }

                } catch(error) {
                    if (!axios.isCancel(error)){
                        // only using last set error message per query set
                        this[queryStateKey].errorMessage = error.message;
                    }
                } finally {
                    // Using Vue.set to ensure reactivity since this is an index-based insertion
                    // Set to null rather than removing because we're referencing by index
                    if (cancelFunctionIndex !== null) {
                        Vue.set(this[queryStateKey].cancelFunctions, cancelFunctionIndex, null);
                    }
                }

                // Once the initial call returns, we can get the other logs asynchronously
                // since we know the total and can spawn as many requests as needed
                const initialCallSucceeded = !append && response && response.data;
                if (initialCallSucceeded) {
                    let nextOffset = offset + limit;
                    const needsMoreData = () => nextOffset < response.data.total;
                    const hitHardLimit = () => nextOffset >= this[queryStateKey].hardLimit;
                    while(needsMoreData() && !hitHardLimit()){
                        const nextLimit = Math.min(limit, this[queryStateKey].hardLimit - nextOffset)
                        this.fetchLogs(type, queryStateKey, { offset: nextOffset, limit: nextLimit, append: true })
                        nextOffset += nextLimit
                    }

                    if (queryStateKey === 'profilesQuery') this.$refs.profileChanges.setProfileData();

                    if (hitHardLimit()){
                        this[queryStateKey].wasLimited = true;
                    }
                }
            },

            async fetchUsers() {
                this.fetchingUsers = true;
                const response = await beef.get(`/api/logging/accounts/${this.account.code}/users?start=${this.from.toISOString()}&end=${this.toExclusive.toISOString()}`);
                this.users = response.data;
                this.fetchingUsers = false;
            },

            async fetchAccountVolumeUsage() {
                try {
                    this.fetchingBrandVolumes = true;
                    let volumesByBrand = {};

                    let monthRange = Math.abs(this.from.diff(this.toExclusive, 'months'));
                    let chartGrouping = monthRange >= 2 ? 'month' : 'day';

                    let params = {
                        groupby: `pickedup[${chartGrouping}]`,
                        from: this.from.format("YYYY/MM/DD"),
                        to: this.toExclusive.format("YYYY/MM/DD")
                    };

                    // only required if we are going to group charts by day
                    if (chartGrouping === "day") {
                        const dailyVolumes = await grouseGet(`/v4/accounts/${this.account.code}/volume`, params);

                        let dailyVolumeResults = dailyVolumes?.results;

                        // build daily volume usage over time
                        dailyVolumeResults?.forEach(dailyVolumeResult => {
                            let brandTotals = dailyVolumeResult.totalByBrand;

                            Object.keys(brandTotals).forEach(brandId => {
                                if (volumesByBrand[brandId]) {
                                    volumesByBrand[brandId].dailyUsage.push({
                                        date: dailyVolumeResult.date,
                                        usage: brandTotals[brandId]
                                    });
                                } else {
                                    volumesByBrand[brandId] = {
                                        monthlyUsage: [],
                                        dailyUsage: [],
                                        grouping: "day"
                                    };
                                }
                            });
                        });
                    }

                    params = {
                        groupby: "pickedup[month]",
                        from: this.from.startOf('month').format("YYYY/MM/DD"),
                        to: this.toExclusive.endOf('month').format("YYYY/MM/DD")
                    };
                    const monthlyVolumes = await grouseGet(`/v4/accounts/${this.account.code}/volume`, params);

                    let monthlyVolumeResults = monthlyVolumes?.results;

                    // build monthly volume usage over time
                    monthlyVolumeResults.forEach(monthlyVolumeResult => {
                        let brandMonthlyTotals = monthlyVolumeResult.totalByBrand;
                        let curMonth = monthlyVolumeResult.date;

                        Object.keys(brandMonthlyTotals).forEach(brandId => {
                            let brandLogs = this.samplingQuery.data.filter(s => s.metadata.brandId === parseInt(brandId));

                            // use volume limit change logs to determine the volume limit for the current month
                            const volumeLimitChanges = brandLogs
                                .filter(s =>
                                    (s.metadata.to.volumeLimit !== undefined && s.metadata.to.volumeLimit !== s.metadata.from.volumeLimit) ||
                                    (s.metadata.to.tempVolumeLimit !== undefined || s.metadata.to.tempVolumeLimit !== s.metadata.from.tempVolumeLimit));

                            let volumeLimit = null;
                            let tempVolumeLimit = null;

                            // we need to get the limits closest to this month in order to determine which limits to check. These will be sorted in ascending order of date
                            let closestLimits = this.getLimitsClosestToDate(curMonth, volumeLimitChanges)

                            for (const limit of closestLimits) {
                                let limitChangedWithinCurMonth = moment(limit.date).format("MM YYYY") === moment(curMonth).format("MM YYYY");
                                let limitChangedAfterCurMonth = moment(limit.date).isAfter(moment(curMonth));

                                if (limitChangedWithinCurMonth) {
                                    // if the limit has changed within the current month, use the temp volume limit if there is one, otherwise, use the updated volume limit
                                    if (limit.metadata.to.tempVolumeLimit) {
                                        tempVolumeLimit = limit.metadata.to.tempVolumeLimit;
                                    } else {
                                        tempVolumeLimit = null;
                                    }
                                    volumeLimit = limit.metadata.to.volumeLimit;
                                } else if (limitChangedAfterCurMonth) {
                                    // if the limit has changed after the current month, use the old volume limit
                                    volumeLimit = limit.metadata.from.volumeLimit;

                                    // for this case, we want to use the value of the earliest limit date to prevent setting the incorrect value if there were multiple volume limit changes within the same month
                                    break;
                                } else {
                                    // if the limit has changed before the current month, use the updated volume limit
                                    volumeLimit = limit.metadata.to.volumeLimit;
                                }
                            }

                            if (volumesByBrand[brandId]) {
                                volumesByBrand[brandId].monthlyUsage.push({
                                    date: curMonth,
                                    usage: brandMonthlyTotals[brandId],
                                    limit: volumeLimit,
                                    tempLimit: tempVolumeLimit
                                })
                            } else {
                                volumesByBrand[brandId] = {
                                    monthlyUsage: [{
                                        date: curMonth,
                                        usage: brandMonthlyTotals[brandId],
                                        limit: volumeLimit,
                                        tempLimit: tempVolumeLimit
                                    }],
                                    grouping: "month"
                                }
                            }
                        });
                    });

                    this.brandVolumes = volumesByBrand;
                } catch (e) {
                    console.error("Error occurred while fetching brand volumes: ", e);
                    this.brandVolumes = {};
                } finally {
                    this.fetchingBrandVolumes = false;
                }
            },

            getBrand(id) {
                return this.idToBrand.get(id);
            },

            getBrandLogs(id) {
                return this.samplingChangesWithinRange.filter(s => s.metadata.brandId === id);
            },

            getBrandRetrosends(id) {
                return this.retrosendsQuery.data.filter(s => s.metadata.brand && s.metadata.brand.id === id);
            },

            async fetchLatestProfileLogs() {
                try {
                    let from = this.toExclusive.subtract(1, 'day').toISOString();
                    let to = this.toExclusive.add(1, 'day').toISOString();

                    const profileLogStrings = ["profile has been authorised", "using existing token", "creating new token",
                        "profile has been unauthorised", "unauthorized from network", "authorisation has been refreshed", "has been added to the account.", "deleted."];
                    let query = [
                        `/rest/accounts/${this.account.code}/activity?from=${from}`,
                        `to=${to}`,
                        `searchStrings=${profileLogStrings.join(",")}`,
                        `offset=${0}`,
                        `limit=${1000}`
                    ].join('&');

                    let response = await mash.get(query);

                    let newLogs = [];
                    for (const latestLog of response.data.data) {
                        let addLog = true;
                        // only compare against first 1000 existing logs since we're only fetching latest 1000 logs
                        for (const existingLog of this.profilesQuery.data.slice(0, 1000)) {
                            if (latestLog.userId === existingLog.userId && latestLog.comment === existingLog.comment && latestLog.date === existingLog.date) {
                                addLog = false;
                                break;
                            }
                        }

                        if (addLog) {
                            newLogs.push(latestLog);
                        }
                    }

                    this.profilesQuery.data = [...newLogs, ...this.profilesQuery.data];

                    this.$refs.profileChanges.setProfileData();
                } catch (e) {
                    console.warn("Error occurred while trying to fetch latest profile logs: ", e);
                }
            },

            getLimitsClosestToDate(date, limits) {
                if (!limits || !limits?.length) return [];

                // sort volume limit changes by ascending order of date
                limits.sort(function(a, b) { return new Date(a.date) - new Date(b.date) })

                let closestLimitDate = null;
                let min = 9999999;

                limits.forEach(limit => {
                    let monthDifference = Math.abs(moment(limit.date).startOf('month').diff(moment(date), 'months'));

                    if (monthDifference < min) {
                        closestLimitDate = moment(limit.date).format("MM YYYY");
                        min = monthDifference;
                    }
                });

                return limits.filter(limit => {
                    return moment(limit.date).format("MM YYYY") === closestLimitDate;
                });
            },
        }
    }
</script>

<style scoped lang="sass">
@import './mini-log'

.setup-changes__header
    display: flex
    align-items: center
    flex-wrap: wrap
    h1
        margin-right: 20px

.setup-changes__flag
    color: var(--be-colour-text-dark)

    &--warning
        color: orange

.setup-changes__flag + .setup-changes__flag
    margin-left: 5px


.symbol-warning
    color: var(--be-colour-warning)

h4
    .css-spinner
        float: right
    input[type="text"]
        margin-left: 1em !important

.setup-changes__description
    margin-bottom: 20px
    .tag-input
        width: auto

.brand-toolbar
    margin-bottom: 20px
    display: flex
    justify-content: space-between
    > input
        margin-bottom: 0

.brand-filters
    > label,
    > span
        user-select: none
        margin-left: 12px
        display: inline-block
    input[type="checkbox"]
        margin-top: 0
    .be-number
        margin-left: 4px
        color: var(--be-colour-muted-text-dark)

.activity-list
    // reset inner view's padding
    margin: -20px -20px 0

.category-buttons
    display: flex
    margin-bottom: 20px

.category-buttons__button
    $right-padding: 15px
    width: 100%
    border: 1px solid #676767
    border-radius: 4px
    padding: 10px $right-padding 0
    margin: 0 20px 0 0
    cursor: pointer
    position: relative
    transition: box-shadow 0.1s
    user-select: none
    h4
        margin: 5px 0 15px
        .symbol-warning
            float: right
        .be-number
            margin-left: 0.25em
            color: var(--be-colour-muted-text-dark)
    &:last-child
        margin-right: 0
    &:hover, &:active
        // border-color: #565656
        background: #454545
    &:hover
        box-shadow: 0 2px 7px rgba(0,0,0,0.35)
    &:active
        top: 1px
        transition: box-shadow 0s
        box-shadow: 0 1px 1px rgba(0,0,0,0.5)
    &.active
        background: #f3f3f3
        color: #363636
        box-shadow: 0 3px 10px rgba(0,0,0,0.3)
        h4 .be-number
            color: var(--be-colour-mid-grey)

    ul
        margin: 0
        padding: 0
    li
        border-top: 1px solid #777
        border-radius: 2px
        padding: 8px $right-padding
        text-align: left
        margin: 0 (-$right-padding)
        list-style: none
        .symbol-warning,
        .reporting-icon
            float: right
            margin-left: 10px
            margin-right: -5px
            font-size: 120%

    &.active li
        border-color: #ccc
        .symbol-warning
            color: var(--be-colour-warning-dark)

::v-deep section.fetching-data,
.category-buttons__button li.fetching-data
    transition: opacity 0.3s
    opacity: 0.3
    .css-spinner
        float: right

.mini-log
    +mini-log

.brands-toolbar
    margin-bottom: 20px

.setup-changes__brand-list
    display: flex
    flex-wrap: wrap

::v-deep .markdown-display
    color: var(--be-colour-text-dark)
    padding: 0

.rise-enter-active
    top: 10px
    opacity: 0
    position: relative
    transition: top 0.2s, opacity 0.3s

.rise-enter-to
    top: 0
    opacity: 1

.setup-changes__no-data
    width: clamp(200px, 50vw, 500px)
    p
        text-align: center
</style>