<template>
    <tr class="setup-profile-changes__auth-update" >
        <td>
            <deq-date :date="log.date" format="ddd D MMM, YYYY HH:mm"/>
            <deq-user :user="log.user"/>
        </td>
        <td>
            <span class="mini-log__detail">
                {{ authUpdateDescription }}
            </span>
            <br/>
            <markdown-text v-if="user.admin" :text="log.comment"/>
        </td>
    </tr>
</template>

<script>
import DeqDate from "@/components/formatters/DeqDate";
import MarkdownText from "@/components/MarkdownText";
import {mapState} from "vuex";
import {formatDate, formatPlural} from "@/app/utils/Format";
import {AUTH_STATE, REFRESH_AUTH_STATE, UNAUTH_STATE} from "@/setup/profiles/ProfileUtils";
import DeqUser from "@/components/formatters/DeqUser";

export default {
    name: "ProfileAuthLog",
    components: {DeqUser, DeqDate, MarkdownText},
    props: {
        log: Object
    },
    computed: {
        ...mapState(['user']),

        authUpdateDescription() {
            switch (this.log.metadata.state) {
                case AUTH_STATE:
                    return "Profile has been authorised";
                case REFRESH_AUTH_STATE:
                    return `Profile authorisation has been refreshed. ${this.log.metadata.authExpireDate ? `New expire date is ${formatDate(this.log.metadata.authExpireDate, 'DD MMMM YYYY, HH:mm')}.` : ''}`;
                case UNAUTH_STATE:
                    return "Profile has been unauthorised";
            }

            return "";
        }
    },
    methods: {
        formatPlural
    }
}
</script>