<template>
    <section>
        <overview-description>
            This shows warnings and errors related to general account setup. This includes problems such as errors
            in landing page dashboards, no Facebook authorisation, and so on.
            <span v-if="uniqueWarningCount">
                This account has
                <strong><deq-number :number="uniqueWarningCount"/> {{ formatPlural(uniqueWarningCount, 'warning')}} </strong>
                that could effect data collection, data quality, reporting, or how other features of Analyse work.
            </span>
            <p>
                If you are concerned about these warnings, please contact our <a href="mailto:support@dataeq.com">support team</a>.
            </p>
        </overview-description>
        <div v-if="isChecking && (!uniqueWarningCount)">
            <loading-message message="Checking account health"/>
        </div>
        <div v-else class="short-animated fadeIn">
            <warning-cluster-card v-if="warnings" :warnings="warnings" :all-possible-warnings="ALL_HEALTH_WARNINGS" :loading="isChecking"/>
        </div>
    </section>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";
import {formatPlural} from "@/app/utils/Format";
import WarningClusterCard from "@/components/cards/WarningClusterCard";
import LoadingMessage from "@/components/LoadingMessage";
import {ALL_HEALTH_WARNINGS} from "@/store/vuex/dataChecks";
import OverviewDescription from "@/app/toplevel/explore/overview/components/OverviewDescription";
import DeqNumber from "@/components/formatters/DeqNumber";

export default {
    components: {DeqNumber, OverviewDescription, LoadingMessage, WarningClusterCard},
    data() {
        return {
            ALL_HEALTH_WARNINGS
        }
    },

    computed: {
        ...mapState('health', ['isChecking', 'warnings']),
        ...mapGetters('health', ['uniqueWarningCount'])
    },

    async mounted() {
        await this.checkAllHealth();
    },

    methods: {
        formatPlural,
        ...mapActions('health', ['checkAllHealth']),
    }

}
</script>


<style scoped lang="sass">

</style>