<template>
    <div class="setup-profile_changes">
        <div class="setup-profile_changes__heading-container">
            <h4>Profiles that were</h4>
            <div class="setup-profile_changes__selector">
                <be-button :active="listToShow === NOT_AUTHORISED_LIST" @click="setListToShow(NOT_AUTHORISED_LIST)">not authorised</be-button>
                <be-button :active="listToShow === CREATED_LIST" @click="setListToShow(CREATED_LIST)">added</be-button>
                <be-button :active="listToShow === DELETED_LIST" @click="setListToShow(DELETED_LIST)">deleted</be-button>
            </div>
            <h4> during this time period</h4>
        </div>

        <search-input
            placeholder="Search for a profile"
            class="setup-profile_changes__search"
            v-model="searchTerm">
        </search-input>


        <label class="checkbox" v-if="listToShow === NOT_AUTHORISED_LIST">
            <input type="checkbox" v-model="showProfilesWithAuthHistoryOnly">
            Only show profiles that have been authorised before
        </label>

        <div v-if="listToShow === NOT_AUTHORISED_LIST">
            <div v-if="filteredProfilesNotAuthorised.length" class="setup-profile_changes__profiles-container">
                <dark-card v-for="profile in filteredProfilesNotAuthorised" :key="profile.handleId">
                    <online-profile :profile="profile"/>
                    <div class="setup-profile_changes__profile-details">
                        <p>{{ profile.message }}</p>
                        <div v-if="profile.authUpdates.length">
                            <div class="setup-profile_changes__heading-container">
                                <h4>Authorisation updates</h4>
                                <be-button style="margin-left: auto" @click="toggleShowMoreAuthUpdates(profile)" v-if="profile.authUpdates.length > authUpdatesTrunced(profile).length">
                                    {{ profile.showMoreAuthUpdates ? 'Hide' : 'Show'}}
                                    {{ profile.authUpdates.length - authUpdatesTrunced(profile).length }} more
                                    {{ formatPlural(profile.authUpdates.length - authUpdatesTrunced(profile).length, 'auth change') }}
                                </be-button>
                            </div>
                            <div class="mini-log">
                                <table class="mini-log__table">
                                    <profile-auth-log
                                        v-for="profileUpdate in (profile.showMoreAuthUpdates ? profile.authUpdates : authUpdatesTrunced(profile))"
                                        :log="profileUpdate"
                                        :key="profileUpdate.userId + ':' + profileUpdate.date"
                                    />
                                </table>
                            </div>
                        </div>
                    </div>
                </dark-card>
            </div>
            <p class="setup-profile_changes__no-profiles" v-else>
                {{ searchTerm ? "No unauthorised profiles found based on current search" : "No profiles were unauthorised on the account during this time period" }}
            </p>
        </div>

        <div v-if="listToShow === CREATED_LIST">
            <div v-if="filteredProfilesCreated.length" class="setup-profile_changes__profiles-container">
                <dark-card v-for="profile in filteredProfilesCreated" :key="profile.handleId">
                    <online-profile :profile="profile"/>
                    <div class="setup-profile_changes__profile-details">
                        <p>{{ profile.message }}</p>
                    </div>
                </dark-card>
            </div>
            <p class="setup-profile_changes__no-profiles" v-else>
                {{ searchTerm ? "No created profiles found based on current search" : "No profiles were added to the account during this time period" }}
            </p>
        </div>

        <div v-if="listToShow === DELETED_LIST">
            <div v-if="filteredProfilesDeleted.length" class="setup-profile_changes__profiles-container">
                <dark-card v-for="profile in filteredProfilesDeleted" :key="profile.handleId">
                    <online-profile :profile="profile"/>
                    <div class="setup-profile_changes__profile-details">
                        <p>{{ profile.message }}</p>
                    </div>
                </dark-card>
            </div>
            <p class="setup-profile_changes__no-profiles" v-else>
                {{ searchTerm ? "No deleted profiles found based on current search" : "No profiles were deleted from the account during this time period" }}
            </p>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import {mapActions, mapState} from "vuex";
import SearchInput from "@/components/inputs/SearchInput";
import {formatDate, formatPlural} from "@/app/utils/Format";
import {profileTypes} from "@/setup/profiles/ProfileUtils";
import OnlineProfile from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfile";
import DarkCard from "@/components/cards/DarkCard";
import {CREATED_STATE, DELETED_STATE, AUTH_STATE, REFRESH_AUTH_STATE, UNAUTH_STATE} from "@/setup/profiles/ProfileUtils";
import ProfileAuthLog from "@/setup/changes/ProfileAuthLog";
import BeButton from "@/components/buttons/BeButton";

export default {
    name: "ProfileChanges",
    components: {BeButton, SearchInput, OnlineProfile, DarkCard, ProfileAuthLog},
    props: {
        dateRange: {
            required: true,
            type: Object
        },
        profileLogs: {
            required: true,
            type: Array
        }
    },

    data: function() {
        return {
            profilesNotAuthorised: [],
            profilesCreated: [],
            profilesDeleted: [],

            showProfilesWithAuthHistoryOnly: false,
            searchTerm: "",
            listToShow: "",
            NOT_AUTHORISED_LIST: "NOT_AUTHORISED_LIST",
            CREATED_LIST: "CREATED_LIST",
            DELETED_LIST: "DELETED_LIST"
        }
    },

    created() {
        this.listToShow = this.NOT_AUTHORISED_LIST;
    },

    computed: {
        ...mapState(['account']),
        ...mapState('profiles', ['profiles']),

        filteredProfilesNotAuthorised() {
            let search = this.searchTerm.toLowerCase();

            let profiles = this.profilesNotAuthorised.filter(profile => profile.handle.toLowerCase().includes(search)
                || profile.handleId.toLowerCase().includes(search)
                || profile.name.toLowerCase().includes(search))

            return this.showProfilesWithAuthHistoryOnly ? profiles.filter(profile => profile.hasBeenAuthorisedBefore) : profiles;
        },

        filteredProfilesCreated() {
            let search = this.searchTerm.toLowerCase();

            return this.profilesCreated.filter(profile => profile.handle.toLowerCase().includes(search)
                || profile.handleId.toLowerCase().includes(search)
                || profile.name.toLowerCase().includes(search))
        },

        filteredProfilesDeleted() {
            let search = this.searchTerm.toLowerCase();

            return this.profilesDeleted.filter(profile => profile.handle.toLowerCase().includes(search)
                || profile.handleId.toLowerCase().includes(search)
                || profile.name.toLowerCase().includes(search))
        }
    },

    methods: {
        ...mapActions('profiles', ['refreshProfiles']),

        formatDate,
        formatPlural,

        toggleShowMoreAuthUpdates(profile) {
            profile.showMoreAuthUpdates = !profile.showMoreAuthUpdates;
        },

        authUpdatesTrunced(profile) {
            return profile.authUpdates.slice(0,5)
        },

        /**
         * Finds a profile either by it's Twitter handle (for Twitter profiles) or URL (all other profile types)
         *
         * @param urlOrHandle
         */
        findProfileByUrlOrHandle(urlOrHandle) {
            let foundProfile = null;
            let isTwitterHandle = false;

            if (urlOrHandle.charAt(0) === '@') {
                urlOrHandle = urlOrHandle.substring(1);
                isTwitterHandle = true;
            }

            for (const profile of this.profiles) {
                if (!profile.deleted) {
                    if (isTwitterHandle) {
                        if (profile.type === profileTypes.twitter && profile.handle === urlOrHandle) {
                            foundProfile = profile;
                            break;
                        }
                    } else if (profile.link === urlOrHandle) {
                        foundProfile = profile;
                        break;
                    }
                }
            }

            return foundProfile;
        },

        setListToShow(listType) {
            this.listToShow = listType;
        },

        /**
         * Construct a log map where the key is the profile's handleId and the value is an array of auth update logs for the profile.
         */
        profileLogsAsMap() {
            // build map of log updates for easy access
            let profileLogs = {}

            this.profileLogs.forEach(log => {
                let logClone = JSON.parse(JSON.stringify(log));

                let profileHandleId;

                // newer profile auth logs have a "metadata" object that contains the type of update (metadata.state = "AUTH" or metadata.state = "UNAUTH"), the profile's handle ID, etc.
                // older logs will not have metadata objects, we therefore need to check the log's description and use it to determine both the state and handle ID.
                if (log.metadata) {
                    profileHandleId = log.metadata.handleId;
                } else {
                    logClone.metadata = {};

                    // get the profile handle ID for the corresponding log
                    // these logs all begin with "Online profile [profile '@handle' (for Twitter) OR 'profile url' (for everything else)]" => extract the url or the handle
                    let urlOrHandle = log.description.slice(
                        log.description.indexOf('[') + 1,
                        log.description.indexOf(']'),
                    );

                    profileHandleId = this.findProfileByUrlOrHandle(urlOrHandle)?.handleId;

                    // set state by checking descriptions
                    if (log.description.includes("profile has been authorised") || log.description.includes("using existing token") ||  log.description.includes("creating new token")) {
                        logClone.metadata.state = AUTH_STATE;
                    } else if (log.description.includes("profile has been unauthorised") || log.description.includes("unauthorized from network")) {
                        logClone.metadata.state = UNAUTH_STATE;
                    } else if (log.description.includes("authorisation has been refreshed")) {
                        logClone.metadata.state = REFRESH_AUTH_STATE;
                    } else if (log.description.includes("has been added to the account.")) {
                        logClone.metadata.state = CREATED_STATE;
                    } else if (log.description.includes("deleted.")) {
                        logClone.metadata.state = DELETED_STATE;
                    }
                }

                if (profileHandleId) {
                    if (profileLogs[profileHandleId]) {
                        profileLogs[profileHandleId].updates.push(logClone);
                    } else {
                        profileLogs[profileHandleId] = {
                            updates: [logClone]
                        }
                    }
                }
            });

            return profileLogs;
        },

        profileUpdatesBeforeOrOnStart(updates) {
            let updatesBeforeStart = [];

            for (const profileUpdate of updates) {
                let updateOccurredBeforeStart = new moment(profileUpdate.date).isSameOrBefore(this.dateRange.start, 'day');

                if (updateOccurredBeforeStart) updatesBeforeStart.push(profileUpdate);
            }

            return updatesBeforeStart;
        },

        profileUpdatesWithinRange(updates) {
            let updatesWithinRange = [];

            for (const profileUpdate of updates) {
                let updateOccurredWithinRange = new moment(profileUpdate.date).isBetween(this.dateRange.start, this.dateRange.end);

                if (updateOccurredWithinRange) updatesWithinRange.push(profileUpdate);
            }

            return updatesWithinRange;
        },

        async setProfileData() {
            this.profilesNotAuthorised = [];
            this.profilesCreated = [];
            this.profilesDeleted = [];

            try {
                await this.refreshProfiles();
                let profileLogs = this.profileLogsAsMap();

                this.profiles.forEach(profile => {
                    let authLogs = [];
                    let createdLogs = [];
                    let deletedLogs = [];

                    let profileUpdateLogs = profileLogs[profile.handleId]?.updates;
                    profileUpdateLogs?.forEach(profileUpdateLog => {
                        switch (profileUpdateLog.metadata.state) {
                            case CREATED_STATE:
                                createdLogs.push(profileUpdateLog);
                                break;
                            case DELETED_STATE:
                                deletedLogs.push(profileUpdateLog);
                                break;
                            case AUTH_STATE:
                            case UNAUTH_STATE:
                            case REFRESH_AUTH_STATE:
                                authLogs.push(profileUpdateLog)
                                break;
                        }
                    });

                    // setting profilesNotAuthorised
                    if (!profile.deleted) {
                        let anAccountDuringTimeframe = new moment(profile.dateCreated).isBefore(moment(this.dateRange.end));

                        if (anAccountDuringTimeframe) {
                            let profileClone = JSON.parse(JSON.stringify(profile));
                            profileClone.message = "";
                            profileClone.authUpdates = [];
                            profileClone.hasBeenAuthorisedBefore = true;
                            profileClone.showMoreAuthUpdates = false;

                            if (authLogs.length) {
                                let updatesBeforeOrOnStart = this.profileUpdatesBeforeOrOnStart(authLogs);
                                let updatesWithinRange = this.profileUpdatesWithinRange(authLogs);

                                profileClone.authUpdates = updatesWithinRange;

                                let authStateBeforeTimeframe = updatesBeforeOrOnStart[0]?.metadata?.state;

                                let authUpdates = [];
                                let unauthUpdates = [];

                                updatesWithinRange.forEach(profileUpdate => {
                                    if (profileUpdate.metadata.state === AUTH_STATE) authUpdates.push(profileUpdate);
                                    if (profileUpdate.metadata.state === UNAUTH_STATE) unauthUpdates.push(profileUpdate);
                                });

                                if ((authStateBeforeTimeframe === AUTH_STATE || authStateBeforeTimeframe === REFRESH_AUTH_STATE) && unauthUpdates.length > 0) {
                                    // if the profile was authorised before/on the start of the selected date range, but was unauthorised at some point between the start and end of the selected date range,
                                    // it was not authorised during the entire period -> add to list
                                    profileClone.message = `This profile was authorised before ${formatDate(this.dateRange.start, 'DD MMMM YYYY')}, but was then unauthorised at some point during this period.`
                                    this.profilesNotAuthorised.push(profileClone);
                                } else if ((authStateBeforeTimeframe === UNAUTH_STATE || !authStateBeforeTimeframe) && !updatesWithinRange.length) {
                                    // if the profile was unauthorised before/on the start of the selected date range, it was not authorised during the entire period -> add to list
                                    profileClone.message = `This profile was authorised before ${formatDate(this.dateRange.start, 'DD MMMM YYYY')} and was not reauthorised during this period.`
                                    this.profilesNotAuthorised.push(profileClone);
                                } else if (authStateBeforeTimeframe === UNAUTH_STATE || !authStateBeforeTimeframe) {
                                    // if the profile was unauthorised before/on the start of the selected date range, it was not authorised during the entire period -> add to list
                                    profileClone.message = `This profile was unauthorised before ${formatDate(this.dateRange.start, 'DD MMMM YYYY')} and may not have been authorised during this entire period.`
                                    this.profilesNotAuthorised.push(profileClone);
                                }
                            } else if (!profile.authorized) {
                                // if the profile is currently unauthorised and has not had any auth updates, it has never been authorised -> add to list
                                profileClone.message = `Profile has never been authorised.`;
                                profileClone.hasBeenAuthorisedBefore = false;
                                this.profilesNotAuthorised.push(profileClone);
                            }
                        }
                    }

                    // setting profilesCreated
                    if (createdLogs.length) {
                        let updatesWithinRange = this.profileUpdatesWithinRange(createdLogs);
                        if (updatesWithinRange.length) {
                            let profileClone = JSON.parse(JSON.stringify(profile));
                            profileClone.message = `This profile was added to the account on ${formatDate(updatesWithinRange[0].date, 'DD MMMM YYYY')}.`
                            this.profilesCreated.push(profileClone);
                        }
                    }

                    // setting profilesDeleted
                    if (deletedLogs.length) {
                        let updatesWithinRange = this.profileUpdatesWithinRange(deletedLogs);
                        if (updatesWithinRange.length) {
                            let profileClone = JSON.parse(JSON.stringify(profile));
                            profileClone.dateDeleted = updatesWithinRange[0].date;
                            profileClone.message = `This profile was deleted from the account on ${formatDate(profileClone.dateDeleted, 'DD MMMM YYYY')}.`
                            this.profilesDeleted.push(profileClone);
                        }
                    }
                });

                this.profilesNotAuthorised = this.profilesNotAuthorised.sort((a, b) => {
                    return b.authUpdates.length > a.authUpdates.length ? 1 : -1;
                });

                this.profilesCreated = this.profilesCreated.sort((a, b) => {
                    return b.dateCreated > a.dateCreated ? 1 : -1;
                });

                this.profilesDeleted = this.profilesDeleted.sort((a, b) => {
                    return b.dateDeleted > a.dateDeleted ? 1 : -1;
                });
            } catch (e) {
                console.error("Error occurred while setting profile data: ", e);
            }
        }
    }
}
</script>

<style scoped lang="sass">
@import './mini-log'

.setup-profile_changes

    .mini-log
        +mini-log

    box-sizing: border-box

    &__search
        width: 30%
        margin-bottom: 10px

    &__selector
        display: flex
        margin: 0 5px
        .be-button:not(:last-of-type) ::v-deep .btn
            border-top-right-radius: 0
            border-bottom-right-radius: 0
        .be-button + .be-button
            & ::v-deep .btn
                border-top-left-radius: 0
                border-bottom-left-radius: 0

    &__profiles-container
        display: grid
        grid-template-columns: 1fr  1fr  1fr 1fr
        row-gap: 10px
        column-gap: 10px
        margin: 20px 0


    &__heading-container
        display: flex
        align-items: center

    &__profile-details
        margin-top: 20px

    &__no-profiles
        position: absolute
        margin-top: 20px
        color: var(--be-colour-muted-text-dark)
        font-style: italic

    .disabled
        transition-delay: 100ms
        transition-duration: 250ms
        filter: grayscale(80%) blur(3px)
        cursor: default
        pointer-events: none
</style>