
const getUserType = user => (
    // DataEQ users
      user.admin ? 'ADMIN'
    : user.system ? 'SERVICE'
    : user.email && (user.email.toLowerCase().indexOf('@brandseye.com') !== -1 || user.email.toLowerCase().indexOf('@dataeq.com') !== -1) ? 'PAST_STAFF'
    // other users
    : 'NORMAL'
)

const isBrandsEyeUser = user => getUserType(user) !== 'NORMAL'

export { getUserType, isBrandsEyeUser }
