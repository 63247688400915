import { render, staticRenderFns } from "./ProfileChanges.vue?vue&type=template&id=f942e260&scoped=true"
import script from "./ProfileChanges.vue?vue&type=script&lang=js"
export * from "./ProfileChanges.vue?vue&type=script&lang=js"
import style0 from "./ProfileChanges.vue?vue&type=style&index=0&id=f942e260&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f942e260",
  null
  
)

export default component.exports